import { AuthType } from './libs/auth/AuthHelper';

/**
 * Checks if all required environment variables are defined
 * @returns {string[]} An array of missing environment variables
 */
export const getMissingEnvVariables = () => {
    // Should be aligned with variables defined in .env.example
    const envVariables = ['REACT_APP_BACKEND_URI'];
    const missingVariables = [];

    for (const variable of envVariables) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/dot-notation
        if (!getEnvVar(variable)) {
            missingVariables.push(variable);
        }
    }

    if (getEnvVar('REACT_APP_AUTH_TYPE') === AuthType.AAD) {
        const aadVariables = ['REACT_APP_AAD_AUTHORITY', 'REACT_APP_AAD_CLIENT_ID'];
        for (const variable of aadVariables) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/dot-notation
            if (!getEnvVar(variable)) {
                missingVariables.push(variable);
            }
        }
    }

    return missingVariables;
};

type EnvironmentVariables = Record<string, string>;

interface WindowWithEnv {
    envVars: EnvironmentVariables;
}

// Get environment variable either from process.env (if present at build time) or from window.envVars (runtime)
export const getEnvVar = (varName: string) => {
    const win = window as unknown as WindowWithEnv;
    return win.envVars[varName] || process.env[varName];
};
